import React, { useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import SupportIcon from '@mui/icons-material/ContactSupportOutlined';
import DailyAssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import WeeklyAssessmentIcon from '@mui/icons-material/AssessmentRounded';
import SkippedIcon from '@mui/icons-material/AssignmentOutlined';
import SessionIcon from '@mui/icons-material/AssignmentIndOutlined';
import SuitabilityIcon from '@mui/icons-material/FlagOutlined';
import AdherenceIcon from '@mui/icons-material/CardMembershipTwoTone';
import {
    collection,
    collectionGroup,
    DocumentSnapshot,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    where
} from 'firebase/firestore';
import { firestoreInstance } from '../config/ApiService';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { RootState } from '../redux/reducers';
import {
    COMPLETION,
    DAILY_ASSESSMENT,
    ENGAGE_ADHERENCE,
    EXERCISES,
    MESSAGE_THREADS,
    SESSIONS,
    SKIPPED,
    TREATMENT_PATIENT_FEEDBACK,
    TRIAGE,
    WEEKLY_ASSESSMENT
} from '../core/utils/collectionNames';

interface IconBadgeProps {
    value: number;
    Icon: OverridableComponent<SvgIconTypeMap>;
}

const IconBadge = ({ value, Icon }: IconBadgeProps) => {
    return (
        <Badge badgeContent={value} color="secondary">
            <Icon />
        </Badge>
    );
};

export const ChatIcon = () => {
    const [value, setValue] = useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    useEffect(() => {
        const handleSnapshots = (snapshots: QuerySnapshot) => {
            const temp = [];
            snapshots.forEach((doc: DocumentSnapshot) => {
                temp.push({ ...doc.data() });
            });
            setValue(temp.length);
        };
        const unsub = onSnapshot(
            query(
                collection(firestoreInstance(), MESSAGE_THREADS),
                where('thread_status', '==', 'open'),
                where('category', '==', 'clinical'),
                where('client_id', '==', client.id)
            ),
            handleSnapshots
        );
        return () => {
            unsub();
        };
    }, [client]);
    return <IconBadge value={value} Icon={SupportIcon} />;
};

export const DailyAssessmentIconBadge = () => {
    const [val, setVal] = useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    useEffect(() => {
        const handler = (snapshots: QuerySnapshot) => {
            const temp = [];
            snapshots.forEach((doc: DocumentSnapshot) => {
                temp.push({ ...doc.data() });
            });
            setVal(temp.length);
        };
        const unsub = onSnapshot(
            query(
                collectionGroup(firestoreInstance(), DAILY_ASSESSMENT),
                where('reviewed', '==', false),
                where('has_adverse_reaction', '==', true),
                where('client_id', '==', client.id)
            ),
            handler
        );
        return () => {
            unsub();
        };
    }, [client, clinician]);

    return <IconBadge value={val} Icon={DailyAssessmentIcon} />;
};

export const WeeklyAssessmentIconBadge = () => {
    const [val, setVal] = useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    useEffect(() => {
        const handler = (snapshots: QuerySnapshot) => {
            const temp = [];
            snapshots.forEach((doc: DocumentSnapshot) => {
                temp.push({ ...doc.data() });
            });
            setVal(temp.length);
        };
        const unsub = onSnapshot(
            query(
                collectionGroup(firestoreInstance(), WEEKLY_ASSESSMENT),
                where('reviewed', '==', false),
                where('client_id', '==', client.id)
            ),
            handler
        );
        return () => {
            unsub();
        };
    }, [client, clinician]);

    return <IconBadge value={val} Icon={WeeklyAssessmentIcon} />;
};

export const ExerciseSkippedBadge = () => {
    const [count, setCount] = useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    React.useEffect(() => {
        const handleSnapshots = (snapshot: QuerySnapshot) => {
            let _count = 0;
            snapshot.forEach((doc: DocumentSnapshot) => {
                if (doc.data()) {
                    _count += 1;
                }
            });
            setCount(_count);
        };
        const unsub = onSnapshot(
            query(
                collection(firestoreInstance(), TREATMENT_PATIENT_FEEDBACK, SKIPPED, EXERCISES),
                where('reviewed', '==', false),
                where('blocked_exercise', '==', true),
                where('client_id', '==', client.id)
            ),
            handleSnapshots
        );
        return () => {
            unsub();
        };
    }, [client, clinician]);
    return <IconBadge value={count} Icon={SkippedIcon} />;
};

export const ExerciseFeedbackBadge = () => {
    const [count, setCount] = useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    React.useEffect(() => {
        const handleSnapshots = (snapshot: QuerySnapshot) => {
            let _count = 0;
            snapshot.forEach((doc: DocumentSnapshot) => {
                if (doc.data()) {
                    _count += 1;
                }
            });
            setCount(_count);
        };
        const unsub = onSnapshot(
            query(
                collection(firestoreInstance(), TREATMENT_PATIENT_FEEDBACK, COMPLETION, SESSIONS),
                where('reviewed', '==', false),
                where('blocked_exercise', '==', true),
                where('client_id', '==', client.id)
            ),
            handleSnapshots
        );
        return () => {
            unsub();
        };
    }, [client, clinician]);
    return <IconBadge value={count} Icon={SessionIcon} />;
};

export const SuitabilityBadge = () => {
    const [count, setCount] = React.useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    React.useEffect(() => {
        const handleSnapshot = (snapshot: QuerySnapshot) => {
            let _count = 0;
            snapshot.forEach(() => {
                _count += 1;
            });
            setCount(_count);
        };
        const unsub = onSnapshot(
            query(
                collection(firestoreInstance(), TRIAGE),
                where('status', '==', 'end'),
                where('predicted_outcome', '==', 'Self Management'),
                where('suitable_for_treatment', '==', 'n/a'),
                where('client_id', '==', client.id),
                orderBy('created_at', 'desc')
            ),
            handleSnapshot
        );
        return () => {
            unsub();
        };
    }, [client]);

    return !client.clinical_services_enabled ? (
        <IconBadge value={count} Icon={SuitabilityIcon} />
    ) : (
        <SuitabilityIcon />
    );
};

export const AdherenceBadge = () => {
    const [count, setCount] = React.useState(0);
    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    React.useEffect(() => {
        const handleSnapshot = (snapshot: QuerySnapshot) => {
            let _count = 0;
            snapshot.forEach(() => {
                _count += 1;
            });
            setCount(_count);
        };
        const unsub = onSnapshot(
            query(
                collection(firestoreInstance(), ENGAGE_ADHERENCE),
                where('client_id', '==', client.id),
                where('is_read', '==', false)
            ),
            handleSnapshot
        );
        return () => {
            unsub();
        };
    }, [client]);

    return <IconBadge value={count} Icon={AdherenceIcon} />;
};
